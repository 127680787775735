import { GET_RATINGS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  ratings: [],
  total: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RATINGS: {
      return {
        ...state,
        ratings: action.payload,
        total: action.total,
      };
    }
    default:
      return state;
  }
};
