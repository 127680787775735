import { ADD_CLINICS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  clinics: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CLINICS: {
      return {
        ...state,
        clinics: action.payload,
      };
    }
    default:
      return state;
  }
};
