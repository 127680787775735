import { ADD_DOCTOR, DELETE_DOCTOR, EDIT_DOCTOR, GET_DOCTORS, SET_DOCTOR_DETAILS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  doctors: [],
  total: 0,
  currentDoctor: null,
  doctorAdded: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCTORS: {
      return {
        ...state,
        doctors: action.payload,
        total: action.total,
        doctorAdded: false,
      };
    }
    case SET_DOCTOR_DETAILS: {
      return {
        ...state,
        currentDoctor: action.payload,
      };
    }
    case ADD_DOCTOR: {
      return {
        ...state,
        doctorAdded: true,
      };
    }
    case EDIT_DOCTOR: {
      return {
        ...state,
        doctors: state.doctors.map(doctor => (doctor.id === action.payload.id ? action.payload : doctor)),
      };
    }
    case DELETE_DOCTOR: {
      return {
        ...state,
        doctors: state.doctors.filter(doctor => doctor.id !== action.payload),
      };
    }
    default:
      return state;
  }
};
