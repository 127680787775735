import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';

const JWTAuth = {
  onLogin: ({ phoneNo, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(`/auth/login/cms?clientId=${process.env.REACT_APP_CLIENT_ID}`, {
            phoneNo,
            password,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
          })
          .then(({ data }) => {
            const { accessToken } = data;
            if (accessToken) {
              // localStorage.setItem('token', accessToken);
              // axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, accessToken));
            } else {
              dispatch(fetchError('Invalid phone/password'));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/users/cms/logout')
        .then(_ => {
          dispatch(fetchSuccess());
          // localStorage.removeItem('token');
          dispatch(setAuthUser(null));
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      // if (!token) {
      //   const token = localStorage.getItem('token');
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      // }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('/users/me')
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
