import IntlMessages from '../../../utils/IntlMessages';
import { People, Star } from '@material-ui/icons';
import React from 'react';

const doctorsMenus = {
  name: <IntlMessages id={'sidebar.doctors.list'} />,
  icon: <People />,
  type: 'item',
  link: '/management/doctors',
};

const viewMenus = {
  name: <IntlMessages id={'sidebar.view.rating'} />,
  icon: <Star />,
  type: 'item',
  link: '/view/rating',
};

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.users'} />,
    type: 'section',
    children: [doctorsMenus],
  },
  {
    name: <IntlMessages id={'sidebar.view'} />,
    type: 'section',
    children: [viewMenus],
  },
];
